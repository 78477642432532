<template>
  <section class="flex flex-col lg:flex-row items-center justify-between max-w-6xl mx-auto px-6 py-16">
    <div class="lg:w-full text-center lg:text-left">
      <h2 class="text-4xl font-semibold mb-4 text-center">{{ $t('landing.employer.hireFlow.title') }}</h2>
      <p class="text-slate-400 mb-8 text-center text-xl">
        {{ $t('landing.employer.hireFlow.sub-title') }}
      </p>
      <div class="grid md:grid-cols-2 gap-6">
        <div class="w-full">
          <div class="space-y-4">
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto mt-4">
              <div class="flex flex-col items-start">
                <h3 class="text-2xl font-extrabold md:w-3/4">{{ $t('landing.employer.hireFlow.card1.title') }}</h3>
                <p class="text-sm mt-5">{{ $t('landing.employer.hireFlow.card1.sub-title') }}
                </p>
                <button class="btn mt-7 text-white text-xs px-6 py-3 rounded-full mt-4 bg-black mx-auto lg:mx-0">
                  {{ $t('landing.employer.hireFlow.card1.button') }}
                </button>
              </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto mt-4">
              <div class="flex flex-col items-start lg:ml-6 ">
                <div class="primary-color-employer w-44 h-44 rounded-full flex items-center justify-center">
                  <img src="../../assets/images/landing/conversation.png" alt="clock" class="w-46 h-auto">
                </div>
              </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
              <div class="flex flex-col items-start">
                <h3 class="text-2xl font-extrabold">{{ $t('landing.employer.hireFlow.card3.title') }}</h3>
                <p class="text-sm mt-5 text-gray-500">{{ $t('landing.employer.hireFlow.card3.sub-title') }}
                </p>
                <button class="btn text-white text-xs px-6 py-3 rounded-full mt-5 bg-black mx-auto lg:mx-0">
                  {{ $t('landing.employer.hireFlow.card3.button') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="absolute left-1/2 transform -translate-x-1/2 h-2/3 w-[5px] mt-10 hidden lg:block" style="background: linear-gradient(to bottom, gray 50%, transparent 50%) repeat-y; background-size: 100% 25px;"></div>

        <div class="w-full">
          <div class="space-y-4">
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
                <div class="flex flex-col items-start">
                  <div class="primary-color-employer w-44 h-44 rounded-full flex items-center justify-center">
                    <img src="../../assets/images/landing/clock.png" alt="clock" class="w-40 h-auto">
                  </div>
                </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
              <div class="flex flex-col items-start">
                <h3 class="text-2xl font-extrabold">{{ $t('landing.employer.hireFlow.card2.title') }}</h3>
                <p class="text-sm mt-5">{{ $t('landing.employer.hireFlow.card2.sub-title') }}
                </p>
                <button class="btn text-white text-xs px-6 py-3 rounded-full mt-4 bg-black mx-auto lg:mx-0">
                  {{ $t('landing.employer.hireFlow.card2.button') }}
                </button>
              </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
              <div class="flex flex-col items-start">
                <div class="primary-color-employer w-44 h-44 rounded-full flex items-center justify-center">
                  <img src="../../assets/images/landing/medal.png" alt="clock" class="w-40 h-auto">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    const baseURL = process.env.VUE_APP_OBJECT_STORAGE_URL;

    return {
      smallCircleDarkLogo: `${baseURL}/resources/logo/small-circle-dark.png`,
      smallCircleLightLogo: `${baseURL}/resources/logo/small-circle-light.png`,
      piggyBankImage: `${baseURL}/resources/img/man-with-pig.png`,
    };
  },
};
</script>
