<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M3.1 1.2C3.14657 1.1379 3.20697 1.0875 3.27639 1.05279C3.34582 1.01807 3.42238 1 3.5 1H12.5C12.5776 1 12.6542 1.01807 12.7236 1.05279C12.793 1.0875 12.8534 1.1379 12.9 1.2L15.876 5.174C16.025 5.359 16.032 5.624 15.886 5.818L8.4 15.8C8.35343 15.8621 8.29303 15.9125 8.22361 15.9472C8.15418 15.9819 8.07762 16 8 16C7.92238 16 7.84582 15.9819 7.77639 15.9472C7.70697 15.9125 7.64657 15.8621 7.6 15.8L0.1 5.8C0.0350889 5.71345 0 5.60819 0 5.5C0 5.39181 0.0350889 5.28655 0.1 5.2L3.1 1.2ZM14.486 4.985L12.68 2.575L11.904 4.988L14.486 4.985ZM10.853 4.989L11.814 2H4.186L5.149 4.995L10.853 4.989ZM5.47 5.995L8 13.866L10.532 5.99L5.47 5.995ZM4.099 4.996L3.319 2.574L1.501 4.999L4.099 4.996ZM1.499 6L6.612 12.817L4.42 5.997L1.499 6ZM9.388 12.817L14.511 5.987L11.583 5.989L9.388 12.817Z" fill="url(#paint0_linear_80_18)"/>
    <defs>
      <linearGradient id="paint0_linear_80_18" x1="3.28285" y1="33.2159" x2="21.4734" y2="29.7472" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF4D00"/>
        <stop offset="1" stop-color="#EF008A"/>
      </linearGradient>
    </defs>
  </svg>
</template>