import axios from 'axios'

const ApiService = {
  init (baseUrl) {
    axios.defaults.baseURL = `${baseUrl}/api`
  },

  get (resource,  params = {}, addConfig) {
    const config = { withCredentials: true, ...addConfig }
    const queryString = this.createQueryString(params)
    const url = queryString ? `${resource}?${queryString}` : resource

    return axios.get(url, config)
  },

  post (resource, data, addConfig) {
    const config = { withCredentials: true, ...addConfig }
    return axios.post(resource, data, config)
  },

  put (resource, data, addConfig) {
    const config = { withCredentials: true, ...addConfig }
    return axios.put(resource, data, config)
  },

  delete (resource, addConfig) {
    const config = { withCredentials: true, ...addConfig }
    return axios.delete(resource, config)
  },

  createQueryString(params = {}) {
    const query = new URLSearchParams()

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        // Append array values (e.g., types[]=value1&types[]=value2)
        value.forEach(item => query.append(`${key}[]`, item))
      } else if (value !== undefined && value !== null) {
        // Append scalar values
        query.append(key, value)
      }
    }

    return query.toString() // Generate the full query string
  },
}

export default ApiService
