<template>
  <div class="pb-8 flex flex-col items-center text-center mx-auto">
    <div class="flex flex-col items-center text-center lg:col-span-8 md:col-span-6 mx-auto">
      <h3 class="mb-4 md:leading-normal text-4xl leading-normal font-semibold">
        {{ $t('job-services.title') }}
      </h3>
      <p class="text-slate-400 max-w-lg text-xl sm:whitespace-pre">
        {{ $t('job-services.description') }}
      </p>
    </div>
  </div>

  <div class="relative w-full overflow-hidden">
    <div class="flex transition-transform duration-500" :style="sliderStyle">
      <div v-for="(item, index) in datas" :key="index" class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2">
        <div class="group relative overflow-hidden dark:shadow-gray-700 transition duration-500">
          <img :src="item.image" alt="" :class="getGradientClass(type)" class="h-[300px] w-full object-cover">
          <div class="flex justify-center items-center p-4 mt-2">
            <a href="#" class="text-lg font-semibold transition-all duration-500">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <button @click="prevSlide"
        class="absolute left-0 top-[43%] transform -translate-y-1/2 rounded-full p-3 transition">
      <img src="@/assets/images/categoryCard/arrow-left.svg" alt="Previous" class="w-12 h-12">
    </button>

    <button @click="nextSlide"
        class="absolute right-0 top-[43%] transform -translate-y-1/2 rounded-full p-3 transition">
      <img src="@/assets/images/categoryCard/arrow-right.svg" alt="Next" class="w-12 h-12">
    </button>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
export default {
  props: {
    type: String
  },
  setup() {
    const datas = ref([
      { image: require('../../assets/images/categoryCard/computer-man.png'), name: 'IT & Development' },
      { image: require('../../assets/images/categoryCard/woman-idea.png'), name: 'UI/UX Design' },
      { image: require('../../assets/images/categoryCard/loud-man.png'), name: 'Marketing' },
      { image: require('../../assets/images/categoryCard/woman-paper.png'), name: 'Sales' },
      { image: require('../../assets/images/categoryCard/woman-cheer.png'), name: 'Management' },
      { image: require('../../assets/images/categoryCard/man-cheer.png'), name: 'Legal & Finance' }
    ]);

    const currentIndex = ref(0);
    const itemsPerSlide = ref(5);

    const updateItemsPerSlide = () => {
      if (window.innerWidth < 640) {
        itemsPerSlide.value = 1;
      } else if (window.innerWidth < 768) {
        itemsPerSlide.value = 2;
      } else if (window.innerWidth < 1024) {
        itemsPerSlide.value = 3;
      } else {
        itemsPerSlide.value = 5;
      }
    };

    onMounted(() => {
      updateItemsPerSlide();
      window.addEventListener('resize', updateItemsPerSlide);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateItemsPerSlide);
    });

    const sliderStyle = computed(() => {
      return { transform: `translateX(-${currentIndex.value * (100 / itemsPerSlide.value)}%)`, transition: 'transform 0.5s ease-in-out' };
    });

    const prevSlide = () => {
      if (currentIndex.value > 0) {
        currentIndex.value--;
      }
    };

    const nextSlide = () => {
      if (currentIndex.value < datas.value.length - itemsPerSlide.value) {
        currentIndex.value++;
      }
    };

    const getGradientClass = (type) => {
      switch (type) {
        case 'talent': return 'bg-gradient';
        case 'employer': return 'bg-gradient-employer';
        case 'index': return 'bg-gradient-mix';
        default: return 'bg-gradient-mix';
      }
    };

    return { datas, currentIndex, sliderStyle, prevSlide, nextSlide, getGradientClass };
  }
};
</script>


<style lang="scss" scoped>
button {
  z-index: 10;
}
</style>
