<template>
 <router-view></router-view>
 <CookieBanner />
</template>

<script setup>
import CookieBanner from "@/components/utility/CookieBanner.vue";
</script>
<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>
