<template>
  <LandingSearch />
    <section class="relative md:py-24 py-16">
      <div class="container">
        <TalentCounter />
      </div>
      <div class="container mt-28">
        <CategoryListCard type="talent"/>
      </div>
      <div class="container mt-28">
        <ApplyFlow />
      </div>
      <div class="container mt-28">
        <Talent3DCard />
      </div>
      <div class="container mt-28">
        <TalentMultiCards />
      </div>
      <PopularJob type="candidate"/>
      <div class="container mt-20">
        <TalentPlatformCard />
      </div>
        <JobQuestion />
    </section>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import TalentCounter from '@/components/landing/talentCounter.vue'
import PopularJob from '@/components/landing/PopularJob.vue';
import LandingSearch from '@/pages/landing/LandingSearch.vue'
import CategoryListCard from '@/components/landing/CategoryListCard.vue'
import ApplyFlow from '@/components/landing/ApplyFlow.vue'
import TalentMultiCards from '@/components/landing/TalentMultiCards.vue'
import TalentPlatformCard from '@/components/landing/TalentPlatformCard.vue'
import JobQuestion from '@/components/landing/JobQuestion.vue'
import Talent3DCard from '@/components/landing/Talent3DCard.vue';

export default {
  name: 'LandingPage',
  components: {
    LandingSearch,
    TalentCounter,
    PopularJob,
    CategoryListCard,
    ApplyFlow,
    TalentMultiCards,
    Talent3DCard,
    TalentPlatformCard,
    JobQuestion
  }
}
</script>

<style lang="scss" scoped>
</style>
