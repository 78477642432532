<template>
  <section class="flex flex-col lg:flex-row items-center justify-between max-w-6xl mx-auto px-6 py-16">
    <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 border-[1.5px] border-black shadow-xl bg-gradient-employer">
      <div class="grid md:grid-cols-2 items-center gap-[30px] h-full">
        <div class="relative bg-gradient w-3/7">
          <img src="../../assets/images/landing/man-and-woman.png"
              class=""
              alt="">
        </div>
        <div>
          <div class="p-4 w-4/7 text-white">
            <h4 class="leading-normal text-4xl mb-3 font-semibold">{{ $t('landing.talent.TalentbaseCard.title') }}</h4>
            <p class="text-lg max-w-xl">{{ $t('landing.talent.TalentbaseCard.description') }}</p>
            <ul class="list-none mt-4">
              <li class="mb-1 flex"><i class="uil uil-check-circle text-xl me-2"></i>
                {{ $t('landing.talent.TalentbaseCard.list1') }}</li>
              <li class="mb-1 flex"><i class="uil uil-check-circle text-xl me-2"></i>
                {{ $t('landing.talent.TalentbaseCard.list2') }}</li>
              <li class="mb-1 flex"><i class="uil uil-check-circle text-xl me-2"></i>
                {{ $t('landing.talent.TalentbaseCard.list3') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
</script>
