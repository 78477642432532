<template>
  <section
      class="relative h-screen flex justify-center items-center bg-gradient-mix overflow-hidden mt-8 sm:mt-0"
  >
    <div class="absolute inset-0"></div>
    <div class="container z-1">
      <div class="grid grid-cols-1 text-left mt-10 relative">
        <h4 class="lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5 font-bold text-white"
        >
        {{ $t('landing.landingHero.title1') }}<br>{{ $t('landing.landingHero.title2') }}
        </h4>
        <p class="text-white text-2xl"
        >
        {{ $t('landing.landingHero.description') }}
        </p>

        <div class="d-flex" id="reserve-form">
          <div class="md:w-5/6">
            <div class="lg:col-span-10 mt-8">
              <div class="border-0 p-3">
                <form @submit.prevent="searchJobs">
                  <div class="registration-form text-dark text-start">
                    <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6 lg:space-x-4">
                      <input
                          type="submit"
                          id="search"
                          name="search"
                          style="height: 60px;"
                          class="btn lg:col-span-2 text-black searchbtn submit-btn bg-white rounded-full px-6"
                          :value="$t('landing.landingHero.forTalentsButton')"
                          @click="$router.push({ name: 'LandingPageTalent' })"
                      >
                      <input
                          type="submit"
                          id="search"
                          name="search"
                          style="height: 60px;"
                          class="btn lg:col-span-3 text-white submit-btn bg-black rounded-full px-6 border-none"
                          :value="$t('landing.landingHero.forCompaniesButton')"
                          @click="$router.push({ name: 'LandingPageEmployer' })"
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-[10%] sm:right-[2rem] hidden lg:block"
      >
        <img
            :src="businessFoxImage"
            alt="Woman illustration"
            class="w-full lg:w-[500px] max-w-full"
            ref="image"
        />
      </div>
    </div>
  </section>
</template>

<script>
import JobService from '@/services/job.service'
import BusinessFoxImage from '@/assets/images/hero/business-fox.png'

export default {
  name : 'LandingSearch',
  components: {
  },
  async mounted () {
    try {
      const jobSearchOptions = await JobService.getJobSearchOptions()
      this.locations = jobSearchOptions.data.locations

    } catch (error) {
      console.error('Error fetching jobs:', error)
    }
  },
  data() {
    return {
      keyword: '',
      locations: [],
      selectedLocation: null,
      businessFoxImage:BusinessFoxImage,
      searchParams: {
        keyword: '',
        location: '',
      }
    }
  },
  computed: {
  },
  methods: {
    async searchJobs() {
      this.searchParams.location = this.selectedLocation
      this.searchParams.keyword = this.keyword

      this.$router.push({
        name: 'JobSearch',
        query: this.searchParams
      })
    }
  }
}
</script>
