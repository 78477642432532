<template>
  <div class="grid grid-cols-1 pb-8 text-center" style="margin-bottom: 50px;">
    <h1 class="mb-4 md:leading-normal text-4xl sm:text-5xl leading-normal font-semibold">Recruiting ohne Vermittlungsprovision</h1>
    <p class="text-slate-500 mx-auto text-2xl px-6">Schauen Sie sich unsere transparente und günstige Preispakete an.<br>Recruiting muss nicht teuer sein. Selbstverständlich kostenlos für Talente.</p>
  </div>
  <section class="flex flex-col lg:flex-row items-center justify-center max-w-7xl mx-auto px-6 py-4">
    <div class="grid md:grid-cols-2 gap-6 w-full">

      <div class="relative bg-pink-500 text-white p-8 rounded-xl shadow-lg">
        <span class="bg-white text-black px-3 py-1 rounded-full text-sm font-bold">Top-Stellenanzeige</span>
        <h3 class="text-4xl font-extrabold mt-4">299/€ <span class="text-lg ml-2">Monat</span></h3>
        <p class="text-xl mt-6">Klassische Stellenanzeige, als Top-Anzeige am Anfang der Liste</p>
        <router-link to="/pricing" class="inline-block mt-6 px-12 py-3 bg-black text-white rounded-full font-semibold">Preise</router-link>
      </div>

      <div class="relative bg-purple-500 text-white p-8 rounded-xl shadow-lg">
        <span class="bg-white text-black px-3 py-1 rounded-full text-sm font-bold">Talentbase</span>
        <h3 class="text-4xl font-extrabold mt-4">799/€<span class="text-lg ml-2">Monat</span></h3>
        <p class="text-xl mt-6">Zugang zu Top-Talenten, ohne Recruiting Provisionen oder Zusatzkosten</p>
        <router-link to="/pricing" class="inline-block mt-6 px-12 py-3 bg-black text-white rounded-full font-semibold">Preise</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PricingCards",
};
</script>

<style scoped>
</style>
