<template>
    <div v-if="!isMobile" class="card-container">
      <div class="content">
        <!-- Text- und Button-Bereich auf der linken Seite -->
        <div class="text-container">
          <h3 class="font-semibold">Remote Jobs</h3>
          <p>
            Finde auch eine Vielzahl von modernen Remote-Stellen, bei denen Du von überall auf der Welt arbeiten kannst.
          </p>
          <button class="text-white px-10 py-3 rounded-full w-full md:w-auto md:mr-6 flex justify-center items-center text-center bg-gradient"
          >
            Registrieren
          </button>
        </div>
        <!-- Spline Viewer-Bereich auf der rechten Seite -->
        <div class="viewer-container">
          <div ref="splineViewer" class="viewer-wrapper">
            <spline-viewer
              v-if="isViewerLoaded"
              :url="viewerUrl"
              class="spline-viewer"
            ></spline-viewer>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Talent3DCard',
    data() {
      return {
        isMobile: false,
        isViewerLoaded: false, // Um sicherzustellen, dass der Spline Viewer geladen ist
        viewerUrl: 'https://prod.spline.design/kQUVssbz24IwnnEg/scene.splinecode',
      };
    },
    mounted() {
      this.checkIfMobile();
      this.loadSplineViewerScript();
      window.addEventListener('resize', this.checkIfMobile); // Eventlistener, um auf Bildschirmänderungen zu reagieren
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.checkIfMobile); // Entfernen des Eventlisteners beim Verlassen der Seite
    },
    methods: {
      checkIfMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
      loadSplineViewerScript() {
        const script = document.createElement('script');
        script.type = 'module';
        script.src = 'https://unpkg.com/@splinetool/viewer/build/spline-viewer.js';
        script.onload = () => {
          this.isViewerLoaded = true;
        };
        document.body.appendChild(script);
      },
      handleButtonClick() {
        alert('Mehr erfahren!'); // Hier kannst du den Button nach deinen Bedürfnissen anpassen
      },
    },
  };
  </script>
  
  <style scoped>
  .card-container {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .text-container {
    flex: 1;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .text-container h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .text-container p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: right;
  }
  
  .text-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0px !important;
  }
  
  .text-container button:hover {
    background-color: #0056b3;
  }
  
  /* Stil für den Spline Viewer-Container */
  .viewer-container {
    flex: 1;
    max-width: 600px;
    border-radius: 12px; /* Abgerundete Ecken für den Kasten */
    overflow: hidden; /* Verhindert, dass der Inhalt außerhalb des Containers sichtbar wird */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Schatten für den Kasten */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .viewer-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spline-viewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1); /* Skaliert den Inhalt */
    transform-origin: center center; /* Skaliert von der Mitte */
  }
  
  @media (max-width: 768px) {
    .card-container {
      display: none; /* Karte bei mobilen Geräten ausblenden */
    }
  }
  </style>
  