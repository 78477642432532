<template>
  <div class="grid grid-cols-1 md:grid-cols-12 gap-6">
    <div class="md:col-span-4 bg-gradient p-6 rounded-2xl flex justify-center items-center">
      <img src="@/assets/images/landing/woman-relax.png" alt="3D Woman" class="w-full h-auto">
    </div>

    <div class="md:col-span-8 text-white rounded-xl">
      <div class="p-6 bg-black mb-5 space-y-6 rounded-xl">
        <h2 class="text-2xl font-bold text-white">Jobs, die du nirgendwo sonst findest</h2>
        <p class="text-xl mb-8 text-white">
          Entdecke Stellen bei Top-Unternehmen und aufstrebenden Start-ups durch unsere exklusiven Partnerschaften!
        </p>
        <div class="flex flex-col sm:flex-row gap-4">
          <router-link class="bg-gradient text-white px-10 py-2 rounded-full w-full sm:w-auto text-center" :to="{name:'userRegister'}">
            Registrieren
          </router-link>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 rounded-2xl">
        <div class="md:col-span-8 bg-gradient text-white p-3 rounded-2xl">
          <div class="p-3">
            <i class="uil uil-clipboard-notes text-3xl"></i>
            <h3 class="mt-2 text-2xl">Lade deinen Lebenslauf hoch und starte deine Reise</h3>
            <h3 class="text-lg">Reibungslos, schnell und einfach – deine nächste Chance wartet.</h3>
          </div>
        </div>
        <div class="md:col-span-4 bg-gradient text-white p-4 rounded-2xl">
          <img src="@/assets/images/landing/phone.png" alt="3D Woman" class="w-full h-auto">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TalentMultiCards"
};
</script>

<style scoped>
</style>
