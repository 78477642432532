<template>
  <div
    v-for="job in jobs"
    :key="job.id"
    class="group relative overflow-hidden flex flex-col gap-6 rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5"
    :class="job.score && job.score >= 5 ? 'border-gradient' :
            job.contract?.slug === 'freelance' ? 'border-gradient-employer' :
            'border border-transparent hover:border-primary-color'"
  >
    <div
      class="relative w-full flex items-center gap-3"
      :class="{ 'mb-12 sm:mb-0': job.score && job.score >= 5 || job.contract?.slug === 'freelance' }"
    >
      <div class="relative flex items-center justify-center bg-white dark:bg-slate-900 w-12 h-12 flex-shrink-0">
        <router-link :to="{name: 'JobDetail', params: {slug: job.slug}}">
          <img :src="job.company?.logoUrl || getRandomPlaceholder()" alt="Company Logo"
              class="w-full h-full object-cover rounded border dark:border-gray-800" />
        </router-link>
      </div>

      <div class="flex flex-col overflow-hidden">
        <router-link :to="{name: 'JobDetail', params: {slug: job.slug}}" class="text-lg transition duration-300 text-hover-gradient">
          {{ job.title }}
        </router-link>

        <router-link
            v-if="job.company?.slug"
            :to="{ name: 'Company', params: { slug: job.company.slug }}"
            class="truncate leading-5"
        >
          <span class="text-slate-500 dark:text-slate-400 text-sm">{{ job.company?.name }}</span>
        </router-link>
        <span v-else class="text-slate-500 dark:text-slate-400 text-sm">{{ job.company?.name }}</span>
      </div>

      <div class="flex items-center self-start gap-3 ml-auto">
        <div
          v-if="job.score && job.score >= 5"
          class="border-gradient rounded-md w-[118px] h-9 flex justify-center items-center gap-2 absolute right-0 top-[calc(100%+12px)] sm:relative sm:top-0"
        >
          <GemIcon />
          <span class="text-gradient text-xs font-bold">TOP JOB</span>
        </div>

        <div
          v-if="job.contract?.slug === 'freelance'"
          class="border-gradient-employer rounded-md w-[118px] h-9 flex justify-center items-center gap-2 absolute right-0 top-[calc(100%+12px)] sm:relative sm:top-0"
        >
          <BriefcaseIcon />
          <span class="text-gradient-employer text-xs font-bold">FREELANCE</span>
        </div>

        <!-- Save Job Icon -->
        <a
          v-if="job.contract?.slug !== 'freelance'"
          @click="toggleSaveJob(job.id)"
          :class="{
            'btn btn-icon rounded-full bg-[#ff3b301a] hover:bg-[#ff3b30] border-[#ff3b30] text-[#ff3b30] hover:text-white': !isJobSaved(job.id),
            'btn btn-icon rounded-full bg-[#ff3b30] text-white': isJobSaved(job.id),
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark size-4">
            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
          </svg>
        </a>

        <a
          v-else
          @click="toggleSaveJob(job.id)"
          :class="{
            'btn btn-icon rounded-full bg-[#ff3b301a] hover:bg-[#9254f2] border-[#9254f2] text-[#9254f2] hover:text-white': !isJobSaved(job.id),
            'btn btn-icon rounded-full bg-[#9254f2] text-white': isJobSaved(job.id),
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark size-4">
            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
          </svg>
        </a>
      </div>
    </div>

    <div class="w-full flex items-center flex-wrap sm:flex-nowrap gap-6 sm:gap-3 overflow-hidden">
      <div class="flex items-start flex-col gap-1">
        <span class="bg-[#ff3b301a] inline-block text-[#ff3b30] text-xs px-2.5 py-0.5 font-semibold rounded-full">{{ job.type?.name }}</span>
        <span class="text-sm">
          <i class="uil uil-map-marker text-primary-color"></i> {{ job.location?.name }}
        </span>
      </div>

      <div class="flex items-center gap-4 ml-auto">
        <div v-if="job.isExpired" class="w-9 h-9 rounded-md flex justify-center items-center border border-[#5781FF]">
          <WatchIcon />
        </div>

        <div class="flex items-end flex-col gap-1 text-sm">
          <span class="text-primary-color">
            <i class="uil uil-clock"></i> {{ job.postDate }}
          </span>
          <span class="font-semibold" v-html="job.category?.name"></span>
        </div>
      </div>

      <div class="flex sm:justify-end min-w-[130px] w-full sm:w-auto">
        <div
          v-if="isJobApplied(job.id)"
          class="flex items-center text-primary-color rounded-md w-auto"
          :class="job.contract?.slug === 'freelance' ? 'text-primary-color-employer' : 'text-primary-color'"
        >
          <span>Applied</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" style="vertical-align: middle;">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <GlassButton
          v-else
          text='Job ansehen'
          :employerStyle="job.contract?.slug === 'freelance'"
          @click.prevent="seeJob(job)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CandidateService from '@/services/candidate.service';
import { getLoginStatus } from '@/events/useGlobalState';
import GemIcon from '@/components/job/list/icons/Gem.vue'
import BriefcaseIcon from '@/components/job/list/icons/Briefcase.vue';
import WatchIcon from '@/components/job/list/icons/Watch.vue';
import placeholderImages from '@/assets/images/job/placeholder/imageImports.js'
import GlassButton from '@/components/utility/GlassButton.vue'

export default {
  props: {
    jobs: {
      type: Array,
      required: true,
    },
  },
  components: {
    GemIcon,
    BriefcaseIcon,
    WatchIcon,
    GlassButton,
  },
  data() {
    return {
      placeholderImages: placeholderImages,
      savedJobIds: [],
      appliedJobIds: [],
    };
  },
  methods: {
    getRandomPlaceholder() {
      return this.placeholderImages[Math.floor(Math.random() * this.placeholderImages.length)];
    },
    isJobSaved(jobId) {
      return this.savedJobIds.includes(jobId);
    },
    isJobApplied(jobId) {
      return this.appliedJobIds.includes(jobId);
    },
    async toggleSaveJob(jobId) {
      if (!getLoginStatus()) {
        localStorage.setItem('redirect_url', this.$router.currentRoute.value.path);
        this.$router.push({ name: 'userLogin' });
        return;
      }

      try {
        if (this.isJobSaved(jobId)) {
          this.savedJobIds = this.savedJobIds.filter((id) => id !== jobId);
          await CandidateService.candidateUnsaveJob(jobId);
        } else {
          await CandidateService.candidateSaveJob(jobId);
          this.savedJobIds = [...this.savedJobIds, jobId];
        }
      } catch (error) {
        console.error('Failed to save job:', error);
      }
    },
    async seeJob(job) {
      localStorage.setItem('redirect_url', this.$router.currentRoute.value.path);
      this.$router.push({ name: 'JobDetail', params: { slug: job.slug } });
    },
  },
  async mounted() {
    if (!getLoginStatus()) {
      return;
    }
    try {
      const savedJobsResponse = await CandidateService.candidateGetSavedJob();
      this.savedJobIds = savedJobsResponse.data.savedJobIds || [];

      const appliedJobsResponse = await CandidateService.candidateGetAppliedJob();
      this.appliedJobIds = appliedJobsResponse.data.appliedJobIds || [];
    } catch (error) {
      console.error('Failed to fetch jobs:', error);
    }
  },
};
</script>

<style scoped>
</style>
