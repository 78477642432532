<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_90_25)">
      <path
        d="M6.5 1C6.10218 1 5.72064 1.15804 5.43934 1.43934C5.15804 1.72064 5 2.10218 5 2.5V3H1.5C1.10218 3 0.720644 3.15804 0.43934 3.43934C0.158035 3.72064 0 4.10218 0 4.5L0 5.884L7.614 7.914C7.86693 7.98135 8.13307 7.98135 8.386 7.914L16 5.884V4.5C16 4.10218 15.842 3.72064 15.5607 3.43934C15.2794 3.15804 14.8978 3 14.5 3H11V2.5C11 2.10218 10.842 1.72064 10.5607 1.43934C10.2794 1.15804 9.89782 1 9.5 1H6.5ZM6.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5V3H6V2.5C6 2.36739 6.05268 2.24021 6.14645 2.14645C6.24021 2.05268 6.36739 2 6.5 2Z"
        fill="white" />
      <path
        d="M6.5 1C6.10218 1 5.72064 1.15804 5.43934 1.43934C5.15804 1.72064 5 2.10218 5 2.5V3H1.5C1.10218 3 0.720644 3.15804 0.43934 3.43934C0.158035 3.72064 0 4.10218 0 4.5L0 5.884L7.614 7.914C7.86693 7.98135 8.13307 7.98135 8.386 7.914L16 5.884V4.5C16 4.10218 15.842 3.72064 15.5607 3.43934C15.2794 3.15804 14.8978 3 14.5 3H11V2.5C11 2.10218 10.842 1.72064 10.5607 1.43934C10.2794 1.15804 9.89782 1 9.5 1H6.5ZM6.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5V3H6V2.5C6 2.36739 6.05268 2.24021 6.14645 2.14645C6.24021 2.05268 6.36739 2 6.5 2Z"
        fill="url(#paint0_linear_90_25)" />
      <path
        d="M0 12.5C0 12.8978 0.158035 13.2793 0.43934 13.5606C0.720644 13.8419 1.10218 14 1.5 14H14.5C14.8978 14 15.2794 13.8419 15.5607 13.5606C15.842 13.2793 16 12.8978 16 12.5V6.84998L8.129 8.94698C8.04448 8.96955 7.95552 8.96955 7.871 8.94698L0 6.84998V12.5Z"
        fill="white" />
      <path
        d="M0 12.5C0 12.8978 0.158035 13.2793 0.43934 13.5606C0.720644 13.8419 1.10218 14 1.5 14H14.5C14.8978 14 15.2794 13.8419 15.5607 13.5606C15.842 13.2793 16 12.8978 16 12.5V6.84998L8.129 8.94698C8.04448 8.96955 7.95552 8.96955 7.871 8.94698L0 6.84998V12.5Z"
        fill="url(#paint1_linear_90_25)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_90_25" x1="1.5" y1="9.00919" x2="9.99211" y2="-3.1842"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#7E56F8" />
        <stop offset="1" stop-color="#E54EDB" />
      </linearGradient>
      <linearGradient id="paint1_linear_90_25" x1="1.5" y1="15.0725" x2="10.2954" y2="2.7712"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#7E56F8" />
        <stop offset="1" stop-color="#E54EDB" />
      </linearGradient>
      <clipPath id="clip0_90_25">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>