<template>
  <section class="flex flex-col lg:flex-row items-center justify-between max-w-8xl mx-auto px-6 py-16">
    <div class="relative overflow-hidden rounded-md">
      <div class="grid md:grid-cols-2 gap-[30px] rounded-full">
        <div class="relative bg-gradient w-full flex justify-center items-center rounded-xl shadow-gray-500 overflow-visible">
          <img src="../../assets/images/landing/woman-at-desk.png"
              class="w-[80%] h-auto object-cover mt-8"
              alt="">
        </div>

        <div class="p-4 text-black justify-center h-full flex items-center">
          <div>
            <h4 class="leading-normal text-2xl md:text-4xl mb-3 font-semibold">
              {{ $t('landing.talent.platformCard.title') }}
            </h4>
            <p class="text-xl w-4/5 text-gray-500">
              {{ $t('landing.talent.platformCard.description') }}
            </p>
            <router-link :to="{ name: 'userRegister' }"
                class="btn text-white px-14 py-3 rounded-full mt-10 bg-gradient">
              {{ $t('landing.talent.platformCard.button') }}
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
</script>
