<template>
  <section
      class="relative h-screen flex justify-center items-center bg-gradient-employer overflow-hidden mt-8 sm:mt-0"
  >
    <div class="absolute inset-0"></div>
    <div class="container z-1">
      <div class="grid grid-cols-1 mt-10 relative">
        <h4 class="lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5 font-bold text-white"
            v-html="$t('landing.talent.searchTitle')">
        </h4>
        <p class="text-white text-xl">
          {{ $t('landing.talent.searchHint-line1') }} <br> {{ $t('landing.talent.searchHint-line2') }}
        </p>
        <div class="d-flex" id="reserve-form">
          <div class="">
            <div class="lg:col-span-10 mt-8">
              <div class="p-3">
                <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6 lg:space-x-4">
                  <input
                      type="submit"
                      id="search"
                      name="search"
                      style="height: 60px;"
                      class="btn lg:col-span-3 text-black searchbtn submit-btn bg-white rounded-full px-5"
                      :value="$t('landing.talent.button1')"
                      @click="redirectToDemo"
                  >
                  <input
                      type="submit"
                      id="search"
                      name="search"
                      style="height: 60px;"
                      class="btn lg:col-span-3 text-white submit-btn bg-black rounded-full border-none"
                      :value="$t('landing.talent.button2')"
                      @click="redirectToDemo"
                  >
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-[1%] right-[-2rem] sm:right-[-8rem] 2xl:right-[5rem] hidden lg:block">
        <img
            :src="man3dImage"
            alt="Man illustration"
            class="w-64 lg:w-[30rem]"
            ref="image"
        />
      </div>
    </div>
  </section>
</template>

<script>
import JobService from '@/services/job.service'
import man3dImage from '@/assets/images/landing/fuchsjobs-working-man-1.png'
import ExternalLink from "@/utils/ExternalLink";

export default {
  name : 'LandingSearch',
  components: {
  },
  async mounted () {
    try {
      const jobSearchOptions = await JobService.getJobSearchOptions()
      this.locations = jobSearchOptions.data.locations

    } catch (error) {
      console.error('Error fetching talents:', error)
    }
  },
  data() {
    return {
      keyword: '',
      locations: [],
      selectedLocation: null,
      man3dImage:man3dImage,
      searchParams: {
        keyword: '',
        location: '',
      }
    }
  },
  computed: {
  },
  methods: {
    async searchCandidate() {
      this.searchParams.location = this.selectedLocation
      this.searchParams.keyword = this.keyword

      this.$router.push({
        name: 'CandidateSearch',
        query: this.searchParams
      })
    },
    redirectToDemo() {
      window.location.href = ExternalLink.appointmentLink
    }
  }
}
</script>
