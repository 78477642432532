import ApiService from '@/services/api.service'

const CandidateService = {
  updateCandidateProfile: async function (candidateData) {
    const formData = new FormData()
    formData.append('name', candidateData.candidateInfo.user.name)
    formData.append('lastName', candidateData.candidateInfo.user.lastName)
    formData.append('intro', candidateData.candidateInfo.intro)
    formData.append('birthday', candidateData.candidateInfo.birthday)
    formData.append('phone', candidateData.candidateContact.phoneNumber)
    formData.append('website', candidateData.candidateContact.websiteURL)
    formData.append('address', candidateData.candidateInfo.address)
    formData.append('city', candidateData.candidateInfo.city)
    formData.append('zipcode', candidateData.candidateInfo.zipcode)
    formData.append('country', candidateData.candidateInfo.country)
    formData.append('jobTitle', candidateData.candidateInfo.jobTitle)
    candidateData.candidateSkills.forEach((skill, index) => {
      Object.entries(skill).forEach(([key, value]) => {
        formData.append(`skills[${index}][${key}]`, value);
      });
    });
    formData.append('cv', candidateData.candidateInfo.cv)
    formData.append('avatar', candidateData.candidateMeta.avatar)
    formData.append('avatarBanner', candidateData.candidateMeta.avatarBanner)
    formData.append('socialMedia', JSON.stringify(candidateData.candidateSocial))
    candidateData.candidateExperiences.forEach((experience, index) => {
      Object.entries(experience).forEach(([key, value]) => {
        formData.append(`experiences[${index}][${key}]`, value);
      });
    });
    candidateData.candidateEducations.forEach((education, index) => {
      Object.entries(education).forEach(([key, value]) => {
        formData.append(`educations[${index}][${key}]`, value);
      });
    });

  try {
      return await ApiService.post('/candidate/profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch (error) {
      console.log(error)

      throw error
    }
  },
  async getCandidateProfile(userUuid) {
    try {
      return await ApiService.get('/candidate/profile/'+userUuid)
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async checkCandidateProfile() {
    try {
      return await ApiService.get('/candidate/profile-check')
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async candidateSaveJob(jobId) {
    try {
      return await ApiService.post('/candidate/save-job/'+jobId)
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async candidateUnsaveJob(jobId) {
    try {
      return await ApiService.post('/candidate/unsave-job/'+jobId)
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async candidateGetSavedJob(currentPage = 1) {
    try {
      return await ApiService.get('/candidate/save-job', {
        currentPage : currentPage
      })
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async candidateApplyJob(jobId) {
    try {
      return await ApiService.post(`/candidate/apply-job/${jobId}`)
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async candidateViewJob(jobId) {
    try {
      return await ApiService.post(`/candidate/view-job/${jobId}`)
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async candidateGetAppliedJob(currentPage = 1) {
    try {
      return await ApiService.get('/candidate/apply-job', {
        currentPage : currentPage
      })
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  async searchCandidates(searchParams) {
    try {
      return await ApiService.get('/candidate/search', searchParams);

    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  },

  candidateGetJobAlert: async function () {
    try {
      return await ApiService.get('/candidate/job/alert', {
      });
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  candidateSetJobAlert: async function (data) {
    try {
      return await ApiService.post('/candidate/job/alert', {
        'keyword' : data.keyword,
        'location' : data.location,
        'jobType' : data.jobType,
        'isActive' : data.isActive,
      });
    } catch (error) {
      console.log(error)

      throw error
    }
  }
}

export default CandidateService
