<template>
  <div>
    <div class="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1">
      <div class="counter-box text-center">
        <h1 class="lg:text-5xl text-4xl font-semibold mb-3 dark:text-white flex justify-center">
          <count-up class="counter-value" :start-val="0" :end-val="statistic.jobCount">0</count-up>
        </h1>
        <h5 class="counter-head text-xl sub-text-color">{{ $t('landing.talent.counter.1') }}</h5>
      </div>
      <div class="counter-box text-center">
        <h1 class="lg:text-5xl text-4xl font-semibold mb-3 dark:text-white flex justify-center">
          <count-up class="counter-value" :start-val="0" :end-val="statistic.companyCount">0</count-up>
        </h1>
        <h5 class="counter-head text-xl sub-text-color">{{ $t('landing.talent.counter.2') }}</h5>
      </div>
      <div class="counter-box text-center">
        <h1 class="lg:text-5xl text-4xl font-semibold mb-3 dark:text-white flex justify-center">
          <count-up class="counter-value" :start-val="0" :end-val="statistic.locationCount">0</count-up>
        </h1>
        <h5 class="counter-head text-xl sub-text-color">{{ $t('landing.talent.counter.3') }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import CountUp from 'vue-countup-v3';
import JobService from '@/services/job.service';

export default {
  data() {
    return {
      statistic: [],
    }
  },
  components:{
    CountUp
  },
  async mounted() {
    try {
      const res = await JobService.getJobStatistic();
      this.statistic = res.data;
    } catch (error) {
      console.error('Error fetching job statistic:', error);
    }
  },
}
</script>