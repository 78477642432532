<template>
  <div class="container md:mt-24 mt-16">
    <div class="grid grid-cols-1 pb-8 text-center">
      <h1 class="mb-4 md:leading-normal text-4xl sm:text-5xl leading-normal font-semibold">{{ $t('popular-job.popularjobs') }}</h1>
      <p v-if="type === 'employer'" class="text-slate-500 mx-auto text-2xl px-6">
        {{ $t('popular-job.description-employer') }}
      </p>
      <p v-else-if="type === 'candidate'" class="text-slate-500 mx-auto text-2xl px-6">
        {{ $t('popular-job.description-talent') }}
      </p>
    </div>

    <div class="shadow-md p-6 flex flex-col md:flex-row gap-2 items-center justify-between">
      <div class="flex items-center dark:bg-[#334155] p-2 rounded-full w-full md:w-[35%] md:ml-10 border border-gray-300">
        <i class="uil uil-briefcase text-gray-500"></i>
        <input type="text" class="bg-transparent focus:outline-none px-3 w-full" placeholder="Welchen Job suchst Du?" />
      </div>
      <div class="flex items-center dark:bg-[#334155] p-2 rounded-full w-full md:w-[35%] border border-gray-300">
        <i class="uil uil-map-marker text-gray-500"></i>
        <input type="text" class="bg-transparent focus:outline-none px-3 w-full" placeholder="Berlin, Germany" />
      </div>
      <router-link :to="{ name: 'JobSearch' }"
          class="text-white px-10 py-3 rounded-full w-full md:w-auto md:mr-6 flex justify-center items-center text-center"
          :class="getButtonClass(type)"
      >Suchen</router-link>
    </div>

    <div class="grid grid-cols-1 mt-8 gap-[30px]">
      <listings :jobs="jobSearchResults"/>
    </div>

    <div class="grid md:grid-cols-1 grid-cols-1 mt-8">
      <div class="md:col-span-12 text-center">
        <router-link :to="{ name: 'JobSearch' }"
            class="duration-500 ease-in-out">{{ $t('popular-job.seeMore') }}<i class="uil uil-arrow-right align-middle"></i></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import feather from 'feather-icons';
import listings from '@/components/job/list/JobList.vue';
import JobService from '@/services/job.service';

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {
    listings,
  },
  async mounted () {
    await nextTick();
    feather.replace();

    try {
      const response = await JobService.searchJobs({
        ...this.searchParams,
      });
      this.jobSearchResults = response.data.jobs.slice(0, 6);
    } catch (error) {
      console.error('Error fetching developer jobs:', error);
    }
  },
  methods: {
    getButtonClass (type) {
      const gradientClasses = {
        'candidate': 'bg-gradient',
        'employer': 'bg-gradient-employer',
      };
      return gradientClasses[type] || 'bg-gradient';
    },
    getIconClass (type) {
      const gradientClasses = {
        'candidate': 'bg-[#ff3b301a] hover:bg-[#ff3b30] border-[#ff3b30] text-[#ff3b30] dark:text-white ',
        'employer': 'bg-[#d9c4ff] icon-border-employer hover:bg-[#e9bfe2] hover:border-[#e9bfe2] hover:text-white dark:text-white ',
      };
      return gradientClasses[type] || gradientClasses['candidate'];
    },
    getLabelClass (type) {
      const gradientClasses = {
        'candidate': 'bg-[#ff3b301a]',
        'employer': 'bg-[#d9c4ff]',
      };

      return gradientClasses[type] || gradientClasses['candidate'];
    },
    getBorderHoverClass (type) {
      switch (type) {
        case 'candidate': return 'hover:border-primary-color';
        case 'employer': return 'hover:border-primary-color-employer';
      }
    },
    getTextHoverClass (type) {
      switch (type) {
        case 'candidate': return 'group-hover:bg-primary-color';
        case 'employer': return 'group-hover:bg-primary-color-employer';
      }
    }
  },
  data() {
    return {
      jobSearchResults: [],
      searchParams: {
        keyword: 'developer',
        category: '',
        location: '',
        types: [],
      }
    }
  },
}
</script>

<style lang="scss" scoped></style>
