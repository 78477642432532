<template>
  <section class="relative h-screen flex justify-center items-center bg-gradient overflow-hidden mt-8 sm:mt-0">
    <div class="absolute inset-0"></div>
    <div class="container z-1 md:p-0">
      <div class="grid grid-cols-1 text-center md:text-start relative">
        <h4 class="lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5 font-bold text-white"
            v-html="$t('landing.employer.searchTitle')">
        </h4>
        <p class="text-white text-xl max-w-2xl" v-html="$t('landing.employer.searchHint')"></p>
        <div id="reserve-form">
          <div class="md:w-3/4">
            <div class="lg:col-span-10 mt-8">
              <div class="p-1">
                <form @submit.prevent="searchJobs">
                  <div class="text-dark text-start rounded-full">
                    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6 rounded-full">
                      <div class="filter-search-form relative rounded-full">
                        <i class="uil uil-briefcase-alt icons"></i>
                        <input
                            v-model="keyword"
                            name="name"
                            type="text"
                            id="job-keyword"
                            class="form-input filter-input-box bg-gray-50 dark:bg-slate-800 rounded-full gap-4"
                            :placeholder="$t('landing.searchBar.keyword')"
                        />
                      </div>
                      <div
                          class="filter-search-form relative bg-gray-50 dark:bg-slate-800 rounded-full md:ml-4">
                        <i class="uil uil-map-marker icons"></i>
                        <div class="w-[92%]">
                          <v-select
                              :options="locations"
                              v-model="selectedLocation"
                              label="name"
                              rounded
                              :reduce="location => location.slug"
                              :placeholder="$t('landing.searchBar.location')"
                              class="ms-10"
                          >
                          </v-select>
                        </div>
                      </div>
                      <input
                          type="submit"
                          id="search"
                          name="search"
                          style="height: 60px;"
                          class="btn text-white searchbtn submit-btn bg-black md:ml-5 rounded-full md:w-[50%] text-lg border-none"
                          :value="$t('landing.searchBar.action')"
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute top-[-20%] right-[-2rem] sm:right-[-8rem] 2xl:right-[-2rem] hidden lg:block">
        <img
            :src="woman3dImage"
            alt="Woman illustration"
            class="w-64 lg:w-80"
            ref="image"
            style="transform: rotate(-15deg);"
        />
      </div>
    </div>

    <div class="absolute w-full overflow-hidden md:py-10 bottom-5">
      <div class="flex whitespace-nowrap animate-scroll">
        <div ref="scrollContainer" class="flex whitespace-nowrap animate-scroll">
          <div class="flex gap-8 ml-5" v-for="n in 5" :key="n">
            <div
                v-for="(title, index) in jobTitles"
                :key="n + '-' + index"
                class="bg-[#ffffff40] text-white px-6 py-3 rounded-full shadow-lg"
            >
              {{ title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import vSelect from 'vue-select'
import JobService from '@/services/job.service'
import woman3dImage from '@/assets/images/landing/fuchsjobs-3d-woman-1.png'

export default {
  name: 'LandingSearch',
  components: {
    vSelect
  },
  async mounted () {
    try {
      const jobSearchOptions = await JobService.getJobSearchOptions()
      this.locations = jobSearchOptions.data.locations

    } catch (error) {
      console.error('Error fetching jobs:', error)
    }
  },
  data() {
    return {
      keyword: '',
      locations: [],
      selectedLocation: null,
      woman3dImage: woman3dImage,
      searchParams: {
        keyword: '',
        location: '',
      },
      jobTitles : ['Front- End Developer', 'UX Designer', 'UI Designer', 'IT Consultant', 'Data Analyst', 'Dev Ops Engineer', 'Cloud Architect', 'Web Developer', 'Product Manager']
    }
  },
  methods: {
    async searchJobs() {
      this.searchParams.location = this.selectedLocation
      this.searchParams.keyword = this.keyword

      this.$router.push({
        name: 'JobSearch',
        query: this.searchParams
      })
    }
  }
}
</script>

<style scoped>
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 140s linear infinite;
}
</style>


