<template>
  <div class="grid md:grid-cols-12 grid-cols-1 pb-8 w-full item-center mx-auto">
    <div class="lg:col-span-8 lg:col-start-3 md:col-span-6 flex flex-col justify-center items-center text-center">
      <h3 class="mb-4 md:text-[34px] md:leading-normal text-2xl leading-normal font-semibold">{{ $t('landing.talent.serviceCard.title') }}</h3>
      <p class="text-slate-400 max-w-xl md:text-[18px]">{{ $t('landing.talent.serviceCard.description') }}</p>
    </div>
  </div>

  <div class="grid grid-cols-12 gap-x-2 gap-y-6 mt-7 max-w-5xl mx-auto">
    <div class="col-span-1 hidden md:block"></div>

    <div class="col-span-12 md:col-span-4 p-4 rounded-md flex flex-col items-center justify-between border shadow-md h-[500px]">
      <div class="w-full h-[80%] bg-[#8661c1] rounded-md flex items-center justify-center">
        <img src="../../assets/images/landing/superwoman.png" alt="Talentbase" class="h-[80%] w-auto">
      </div>
      <h3 class="text-lg font-semibold mb-6">{{ $t('landing.talent.serviceCard.talentbase') }}</h3>
    </div>

    <div class="col-span-1 hidden md:block"></div>

    <div class="col-span-12 md:col-span-4 p-4 rounded-md flex flex-col items-center justify-between border shadow-md h-[500px]">
      <div class="w-full h-[80%] bg-[#ff3b5c] rounded-md flex items-center justify-center">
        <img src="../../assets/images/landing/cross-arm-man.png" alt="Stellenanzeigen" class="h-[80%] w-auto">
      </div>
      <h3 class=" text-lg font-semibold mb-6">{{ $t('landing.talent.serviceCard.jobads') }}</h3>
    </div>
    <div class="col-span-1 hidden md:block"></div>
  </div>

</template>

<script>
export default {
  data() {
    const baseURL = process.env.VUE_APP_OBJECT_STORAGE_URL;

    return {
      stellenanzeigenImage: `${baseURL}/resources/img/job-show.png`
    };
  },
};
</script>
