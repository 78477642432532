import { createI18n } from 'vue-i18n';

import en from './en.json';
import de from './de.json';

const messages = {
  en,
  de,
};

const i18n = createI18n({
  legacy: false,
  locale: 'de',
  fallbackLocale: 'de',
  messages,
});

export default i18n;
