import { reactive } from 'vue';
import placeholderAvatar from '@/assets/images/placeholder/avatar.svg'

function getAvatar() {
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  return  userInfo?.avatar ?? placeholderAvatar;
}

export function getLoginStatus() {
  const loginStatus = localStorage.getItem('loginStatus');

  return  loginStatus === 'success';
}

export function getRole() {
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  return  userInfo?.role ?? 'candidate';
}

export const globalState = reactive({
  avatar: getAvatar(),
});

export function updateAvatar() {
  globalState.avatar = getAvatar();
}
