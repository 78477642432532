<template>
  <div class="button-wrap" :class="{ 'employer': employerStyle }">
    <button>
      <span>{{ text }}</span>
    </button>
    <div class="button-shadow"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    employerStyle: {
      type: Boolean,
      required: false,
    }
  },
}
</script>

<style lang="scss" scoped>
/* Defs */
@property --angle-1 {
  syntax: "<angle>";
  inherits: false;
  initial-value: -75deg;
}

@property --angle-2 {
  syntax: "<angle>";
  inherits: false;
  initial-value: -45deg;
}

/* ========== BUTTON ========== */

/* Button Wrap Container */
.button-wrap {
  position: relative;
  display: inline-flex;
  z-index: 2;
  border-radius: 6px;
  background: transparent;
  pointer-events: none;
  transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1);
}

/* Button Shadow Container */
.button-shadow {
  --shadow-cuttoff-fix: 2em;
  position: absolute;
  width: calc(100% + var(--shadow-cuttoff-fix));
  height: calc(100% + var(--shadow-cuttoff-fix));
  top: calc(0% - var(--shadow-cuttoff-fix) / 2);
  left: calc(0% - var(--shadow-cuttoff-fix) / 2);
  filter: blur(clamp(2px, 0.125em, 12px));
  -webkit-filter: blur(clamp(2px, 0.125em, 12px));
  -moz-filter: blur(clamp(2px, 0.125em, 12px));
  -ms-filter: blur(clamp(2px, 0.125em, 12px));
  overflow: visible;
  pointer-events: none;
}

/* Shadow */
.button-shadow::after {
  content: "";
  position: absolute;
  z-index: 0;
  inset: 0;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
  width: calc(100% - var(--shadow-cuttoff-fix) - 0.25em);
  height: calc(100% - var(--shadow-cuttoff-fix) - 0.25em);
  top: calc(var(--shadow-cuttoff-fix) - 0.5em);
  left: calc(var(--shadow-cuttoff-fix) - 0.875em);
  padding: 0.125em;
  box-sizing: border-box;
  mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  mask-composite: exclude;
  transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1);
  overflow: visible;
  opacity: 1;
}

/* ========== BUTTON BASE STYLES ========== */

button {
  /* Basic Styling */
  --border-width: clamp(1px, 0.0625em, 4px);
  all: unset;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: auto;
  z-index: 3;
  background: linear-gradient(45deg, hsla(18, 100%, 50%, 1) 0%, hsla(325, 100%, 47%, 1) 100%);
  border-radius: 6px;
  box-shadow: inset 0 0.125em 0.125em rgba(0, 0, 0, 0.05),
    inset 0 -0.125em 0.125em rgba(255, 255, 255, 0.5),
    0 0.25em 0.125em -0.125em rgba(0, 0, 0, 0.2),
    0 0 0.1em 0.25em inset rgba(255, 255, 255, 0.2),
    0 0 0 0 rgba(255, 255, 255, 1);
  backdrop-filter: blur(clamp(1px, 0.125em, 4px));
  -webkit-backdrop-filter: blur(clamp(1px, 0.125em, 4px));
  -moz-backdrop-filter: blur(clamp(1px, 0.125em, 4px));
  -ms-backdrop-filter: blur(clamp(1px, 0.125em, 4px));
  transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1);
}

button:hover {
  transform: scale(0.975);
  backdrop-filter: blur(0.01em);
  -webkit-backdrop-filter: blur(0.01em);
  -moz-backdrop-filter: blur(0.01em);
  -ms-backdrop-filter: blur(0.01em);
  box-shadow: inset 0 0.125em 0.125em rgba(0, 0, 0, 0.05),
    inset 0 -0.125em 0.125em rgba(255, 255, 255, 0.5),
    0 0.15em 0.05em -0.1em rgba(0, 0, 0, 0.25),
    0 0 0.05em 0.1em inset rgba(255, 255, 255, 0.5),
    0 0 0 0 rgba(255, 255, 255, 1);
}

/* Button Text */
button span {
  position: relative;
  display: block;
  min-width: 115px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.05em;
  font-weight: 700;
  font-size: 0.9em;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0em 0.25em 0.05em rgba(0, 0, 0, 0.1);
  transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1);
  padding-inline: 12px;
  padding-block: 6px;
}

button:hover span {
  text-shadow: 0.025em 0.025em 0.025em rgba(0, 0, 0, 0.12);
}

/* Text */
button span::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: calc(100% - var(--border-width)); /* Prevent overlapping border */
  height: calc(100% - var(--border-width));
  top: calc(0% + var(--border-width) / 2);
  left: calc(0% + var(--border-width) / 2);
  box-sizing: border-box;
  border-radius: 6px;
  overflow: clip;
  background: linear-gradient(
    var(--angle-2),
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 40% 50%,
    rgba(255, 255, 255, 0) 55%
  );
  z-index: 3;
  mix-blend-mode: screen;
  pointer-events: none;
  background-size: 200% 200%;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  transition: background-position calc(400ms * 1.25)
      cubic-bezier(0.25, 1, 0.5, 1),
    --angle-2 calc(400ms * 1.25) cubic-bezier(0.25, 1, 0.5, 1);
}

button:hover span::after {
  background-position: 25% 50%;
}

button:active span::after {
  background-position: 50% 15%;
  --angle-2: -15deg;
}

/* Touch Devices */
@media (hover: none) and (pointer: coarse) {
  button span::after,
  button:active span::after {
    --angle-2: -45deg;
  }
}

/* ========== BUTTON OUTLINE ========== */

/* Outline */
button::after {
  content: "";
  position: absolute;
  z-index: 1;
  inset: 0;
  border-radius: 6px;
  width: calc(100% + var(--border-width));
  height: calc(100% + var(--border-width));
  top: calc(0% - var(--border-width) / 2);
  left: calc(0% - var(--border-width) / 2);
  padding: var(--border-width);
  box-sizing: border-box;
  background: conic-gradient(
      from var(--angle-1) at 50% 50%,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0) 5% 40%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 60% 95%,
      rgba(0, 0, 0, 0.5)
    ),
    linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  mask-composite: exclude;
  transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1),
    --angle-1 500ms ease;
  box-shadow: inset 0 0 0 calc(var(--border-width) / 2) rgba(255, 255, 255, 0.5);
}

button:hover::after {
  --angle-1: -125deg;
}

button:active::after {
  --angle-1: -75deg;
}

@media (hover: none) and (pointer: coarse) {
  button::after,
  button:hover::after,
  button:active::after {
    --angle-1: -75deg;
  }
}

/* Shadow Hover */
.button-wrap:has(button:hover) .button-shadow {
  filter: blur(clamp(2px, 0.0625em, 6px));
  -webkit-filter: blur(clamp(2px, 0.0625em, 6px));
  -moz-filter: blur(clamp(2px, 0.0625em, 6px));
  -ms-filter: blur(clamp(2px, 0.0625em, 6px));
  transition: filter 400ms cubic-bezier(0.25, 1, 0.5, 1);
}

.button-wrap:has(button:hover) .button-shadow::after {
  top: calc(var(--shadow-cuttoff-fix) - 0.875em);
  opacity: 1;
}

/* Rotation */
.button-wrap:has(button:active) {
  transform: rotate3d(1, 0, 0, 25deg);
}

.button-wrap:has(button:active) button {
  box-shadow: inset 0 0.125em 0.125em rgba(0, 0, 0, 0.05),
    inset 0 -0.125em 0.125em rgba(255, 255, 255, 0.5),
    0 0.125em 0.125em -0.125em rgba(0, 0, 0, 0.2),
    0 0 0.1em 0.25em inset rgba(255, 255, 255, 0.2),
    0 0.225em 0.05em 0 rgba(0, 0, 0, 0.05),
    0 0.25em 0 0 rgba(255, 255, 255, 0.75),
    inset 0 0.25em 0.05em 0 rgba(0, 0, 0, 0.15);
}

.button-wrap:has(button:active) .button-shadow {
  filter: blur(clamp(2px, 0.125em, 12px));
  -webkit-filter: blur(clamp(2px, 0.125em, 12px));
  -moz-filter: blur(clamp(2px, 0.125em, 12px));
  -ms-filter: blur(clamp(2px, 0.125em, 12px));
}

.button-wrap:has(button:active) .button-shadow::after {
  top: calc(var(--shadow-cuttoff-fix) - 0.5em);
  opacity: 0.75;
}

.button-wrap:has(button:active) span {
  text-shadow: 0.025em 0.25em 0.05em rgba(0, 0, 0, 0.12);
}
 /* ------------------------- */
.button-wrap.employer button {
  background: linear-gradient(180deg, hsla(304, 74%, 60%, 1) 0%, hsla(255, 92%, 65%, 1) 100%);
}
</style>
