<template>
  <section class="flex flex-col lg:flex-row items-center justify-between max-w-8xl mx-auto px-6 py-16">
    <div class="relative overflow-hidden rounded-md">
      <div class="grid md:grid-cols-2 gap-[30px] rounded-full">
        <div class="relative bg-gradient-employer w-full flex justify-center items-center rounded-xl shadow-gray-500 overflow-visible">
          <img src="../../assets/images/landing/man-with-mic.png"
              class="w-[350px] h-auto object-cover mt-8"
              alt="">
        </div>

        <div class="p-4 text-black">
          <h4 class="leading-normal text-4xl mb-3 font-semibold">{{ $t('landing.employer.platformCard.title-line1') }}<br> {{ $t('landing.employer.platformCard.title-line2') }}</h4>
          <p class="text-xl w-4/5 text-gray-500">{{ $t('landing.employer.platformCard.description') }}</p>
          <button class="btn text-white px-7 py-3 rounded-full mt-10 bg-gradient-employer">
            {{ $t('landing.employer.platformCard.button') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
</script>
