import ApiService from '@/services/api.service'

const JobService = {
  getJobSearchOptions: async function () {
    return await ApiService.get('/job/search/options').then(res => {
      return res
    })
  },
  searchJobs: async function (searchParams) {
    console.log(searchParams)

    return await ApiService.get(`/job/search`, searchParams).then(res => {
      return res
    })
  },
  getJob: async function (jobId) {
    return await ApiService.get(`/job/get/`+ jobId).then(res => {
      return res
    })
  },
  getJobBySlug: async function (slug) {
    return await ApiService.get(`/job/get/slug/`+ slug).then(res => {
      return res
    })
  },
  getJobStatistic: async function () {
    return await ApiService.get('/job/statistic').then(res => {
      return res
    })
  },
}

export default JobService
