<template>
  <section class="flex flex-col lg:flex-row items-center justify-between max-w-6xl mx-auto px-6 py-16">
    <div class="lg:w-full text-center lg:text-left">
      <h2 class="text-4xl font-semibold mb-4 text-center">
        {{ $t('landing.employer.applyFlow.title') }}
      </h2>
      <p class="text-slate-400 mb-8 text-center text-xl p-2">
        {{ $t('landing.employer.applyFlow.sub-title') }}
      </p>
      <div class="grid md:grid-cols-2 gap-6">
        <div class="w-full">
          <div class="space-y-4">
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto mt-4">
              <div class="flex flex-col items-start">
                <h3 class="text-2xl font-extrabold">{{ $t('landing.employer.applyFlow.card1.title') }}</h3>
                <p class="text-s mt-5">{{ $t('landing.employer.applyFlow.card1.sub-title') }}
                </p>
                <router-link :to="{ name: 'userRegister' }"
                  class="btn mt-7 text-white text-xs px-6 py-3 rounded-full mt-4 bg-black mx-auto lg:mx-0">
                  {{ $t('landing.employer.applyFlow.card1.button') }}
                </router-link>
              </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto mt-4">
              <div class="flex flex-col items-start lg:ml-6 ">
                <div class="bg-gradient w-52 h-52 rounded-full flex items-center justify-center overflow-hidden">
                  <img src="../../assets/images/landing/pam-cv.png" alt="clock" class="w-36 h-auto bg-white mt-14 object-cover">
                </div>
              </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
              <div class="flex flex-col items-start">
                <h3 class="text-2xl font-extrabold">{{ $t('landing.employer.applyFlow.card3.title') }}</h3>
                <p class="text-s mt-5">{{ $t('landing.employer.applyFlow.card3.sub-title') }}
                </p>
                <router-link :to="{ name: 'JobSearch' }"
                    class="btn text-white text-xs px-6 py-3 rounded-full mt-5 bg-black mx-auto lg:mx-0">
                  {{ $t('landing.employer.applyFlow.card3.button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="absolute left-1/2 transform -translate-x-1/2 h-2/3 w-[5px] mt-10 hidden lg:block">
          <div class="h-full w-full" style="background: linear-gradient(to bottom, gray 50%, transparent 50%) repeat-y; background-size: 100% 25px;"></div>

          <div class="absolute left-1/2 -translate-x-1/2 top-0 dark:text-white text-gray-700 dark:bg-[#0f172a] bg-white font-semibold px-2 whitespace-nowrap text-center">
            Schritt <br><span class="text-xl font-extrabold">01</span>
          </div>
          <div class="absolute left-1/2 -translate-x-1/2 top-1/3 dark:text-white text-gray-700 dark:bg-[#0f172a] bg-white font-semibold px-2 whitespace-nowrap text-center">
            Schritt <br><span class="text-xl font-extrabold">02</span>
          </div>
          <div class="absolute left-1/2 -translate-x-1/2 top-2/3 dark:text-white text-gray-700 dark:bg-[#0f172a] bg-white font-semibold px-2 whitespace-nowrap text-center">
            Schritt <br><span class="text-xl font-extrabold">03</span>
          </div>
        </div>

        <div class="w-full">
          <div class="space-y-4">
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
                <div class="flex flex-col items-start">
                  <div class="bg-gradient w-52 h-52 rounded-full flex items-center justify-center overflow-hidden">
                    <img src="../../assets/images/landing/woman-smile.png" alt="clock" class="w-40 h-auto object-cover">
                  </div>
                </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
              <div class="flex flex-col items-start">
                <h3 class="text-2xl font-extrabold">{{ $t('landing.employer.applyFlow.card2.title') }}</h3>
                <p class="text-s mt-5">{{ $t('landing.employer.applyFlow.card2.sub-title') }}
                </p>
                <router-link :to="{ name: 'userRegister' }"
                    class="btn text-white text-xs px-6 py-3 rounded-full mt-4 bg-black mx-auto lg:mx-0">
                  {{ $t('landing.employer.applyFlow.card2.button') }}
                </router-link>
              </div>
            </div>
            <div class="p-4 rounded-lg flex items-center gap-4 w-3/5 mx-auto">
              <div class="flex flex-col items-start">
                <div class="bg-gradient w-52 h-52 rounded-full flex items-center justify-center">
                  <img src="../../assets/images/landing/handshake.png" alt="clock" class="w-44 h-auto">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    const baseURL = process.env.VUE_APP_OBJECT_STORAGE_URL;

    return {
      smallCircleDarkLogo: `${baseURL}/resources/logo/small-circle-dark.png`,
      smallCircleLightLogo: `${baseURL}/resources/logo/small-circle-light.png`,
      piggyBankImage: `${baseURL}/resources/img/man-with-pig.png`,
    };
  },
};
</script>
