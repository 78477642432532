<template>
  <LandingHero />
    <section class="relative md:py-24 py-16">
      <div class="container mb-16">
        <TalentCounter />
      </div>
      <div class="container">
        <ServiceCard />
      </div>
      <div class="container md:py-24 py-16">
        <CategoryListCard />
      </div>
      <div class="container">
        <TalentbaseCard />
      </div>
      <div class="container">
        <PriceCard />
      </div>
      <Popularjob type="candidate" />
    </section>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import ServiceCard from '@/components/landing/ServiceCard.vue';
import PriceCard   from '@/components/landing/PriceCard.vue'
import Popularjob from '@/components/landing/PopularJob.vue';
import LandingHero from '@/pages/landing/LandingHero.vue'
import TalentCounter from '@/components/landing/talentCounter.vue'
import CategoryListCard from '@/components/landing/CategoryListCard.vue'
import TalentbaseCard from '@/components/landing/TalentbaseCard.vue'

export default {
  name: 'LandingPage',
  components: {
    TalentCounter,
    CategoryListCard,
    TalentbaseCard,
    LandingHero,
    ServiceCard,
    PriceCard,
    Popularjob,
  }
}
</script>

<style lang="scss" scoped>
</style>
