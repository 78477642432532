<template>
  <div v-if="!isMobile" class="card-container py-8">
    <div class="viewer-container">
      <spline-viewer
        v-if="isViewerLoaded"
        :url="viewerUrl"
        class="spline-viewer"
      ></spline-viewer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Talent3DCard",
  data() {
    return {
      isMobile: false,
      isViewerLoaded: false,
      viewerUrl: "https://prod.spline.design/K-4yM0vLKcRwUuSI/scene.splinecode",
    };
  },
  mounted() {
    this.checkIfMobile();
    this.loadSplineViewerScript();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    loadSplineViewerScript() {
      const script = document.createElement("script");
      script.type = "module";
      script.src =
        "https://unpkg.com/@splinetool/viewer/build/spline-viewer.js";
      script.onload = () => {
        this.isViewerLoaded = true;
      };
      document.body.appendChild(script);
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: center; /* Horizontale Zentrierung */
  align-items: center; /* Vertikale Zentrierung */
  margin-bottom: 50px;
}

.viewer-container {
  width: 600px; /* Maximale Breite */
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spline-viewer {
  width: 100%;
  height: 400px; /* Feste Höhe für bessere Darstellung */
}

@media (max-width: 768px) {
  .card-container {
    display: none;
  }
}
</style>
