import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/scss/tailwind.scss'
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import '@/assets/libs/@iconscout/unicons/css/line.css'
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSmoothScroll from 'vue3-smooth-scroll';
import ApiService from '@/services/api.service'
import '@fortawesome/fontawesome-free/css/all.css';
import i18n from '../locales/i18n';
import PrimeVue from 'primevue/config';

ApiService.init(process.env.VUE_APP_BACKEND_API_URL)

const app = createApp(App)
app.config.devtools = true;
app.use(VueSmoothScroll);
app.use(VueSweetalert2);
app.use(router);
app.use(i18n);

app.use(PrimeVue);

app.mount('#app')


