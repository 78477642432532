<template>
  <div class="container md:mt-24 mt-16">
    <div class="grid grid-cols-1 pb-8 text-center">
      <h3 id="faq" class="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">{{ $t('job-questions.title') }}</h3>
      <p class="text-slate-400 max-w-xl mx-auto">{{ $t('job-questions.description') }}</p>
    </div>

    <div class="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
      <div v-for="item in datas" :key="item" class="flex">
        <i class="uil uil-question-circle text-2xl text-[#fe4b03] mr-3"></i>
        <div class="flex-1">
          <h5 class="mb-2 text-lg font-semibold">{{item.name}} <span class="text-emerald-600">{{item.name2}}</span> {{item.name3}}</h5>
          <p class="text-slate-400">{{item.desc}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datas: [
        {
          name: 'Was ist die Fuchsjobs Talentbase?',
          desc: 'Die Talentbase ist eine Plattform, auf der sich Fachkräfte, insbesondere IT, registrieren können, um von Unternehmen für passende Jobangebote entdeckt zu werden.'
        },
        {
          name: 'Was ist die Fuchsjobs Jobplattform?',
          desc: "Unsere Jobplattform ermöglicht es Unternehmen, Stellenanzeigen zu veröffentlichen und direkt mit qualifizierten Talenten aus unserer Talentbase in Kontakt zu treten."
        },
        {
          name: 'Kostet die Nutzung der Talentbase etwas?',
          desc: 'Nein, die Registrierung und Nutzung der Talentbase ist für Talente immer komplett kostenlos.'
        },
        {
          name: ' Kann ich mich auch direkt auf Jobs bewerben?',
          desc: "Ja! Auf unserer Jobplattform kannst du gezielt nach passenden Stellen suchen und Dich auch direkt mit wenigen Klicks bewerben. Wir haben eine sehr große Anzahl an offenen Stellenausschreibungen."
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
