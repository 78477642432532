import { createWebHistory, createRouter } from "vue-router";
import LandingPageTalent from "@/pages/landing/LandingTalent.vue"
import LandingPageEmployer from "@/pages/landing/LandingEmployer.vue"
import LandingPageIndex from "@/pages/landing/LandingIndex.vue"

const routes = [
  {
    path: "/",
    name: "",
    component: () => import('@/pages/Base.vue'),
    children: [
      {
        path: '/landing/about',
        name: "LandingPageIndex",
        component: LandingPageIndex,
      },
      {
        path: "/impressum",
        name: "impressum",
        component: () => import('@/components/utility/terms.vue'),
      },
      {
        path: "/datenschutz",
        name: "datenschutz",
        component: () => import('@/components/utility/privacy.vue'),
      },
      {
        path: '/',
        name: "LandingPageTalent",
        component: LandingPageTalent,
      },
      {
        path: '/landing/employer',
        name: "LandingPageEmployer",
        component: LandingPageEmployer,
      },
      {
        path: "/job-search",
        name: "JobSearch",
        component: () => import('@/pages/job/JobSearch.vue'),
      },
      {
        path: "job-detail/:slug",
        name: "JobDetail",
        component: () => import('@/pages/job/JobDetail.vue'),
      },
      {
        path: "/candidate/profile-setting",
        name: "CandidateProfileSetting",
        component: () => import('@/pages/candidate/CandidateProfileSetting.vue'),
      },
      {
        path: "/candidate/profile",
        name: "CandidateProfile",
        component: () => import('@/pages/candidate/CandidateProfile.vue'),
      },
      {
        path: "/candidate/profile/:uuid",
        name: "CandidateProfilePublic",
        component: () => import('@/pages/candidate/CandidateProfile.vue'),
      },
      {
        path: "/candidate/job",
        name: "CandidateJob",
        component: () => import('@/pages/candidate/CandidateJob.vue'),
      },
      {
        path: "/candidate/job/alert",
        name: "CandidateJobAlert",
        component: () => import('@/pages/candidate/CandidateJobAlert.vue'),
      },
      {
        path: "/candidate-search",
        name: "CandidateSearch",
        component: () => import('@/pages/candidate/CandidateSearch.vue'),
      },
      {
        path: "/employer",
        name: "EmployerBase",
        component: () => import('@/pages/employer/EmployerBase.vue'),
        children: [
          {
            path: "/employer",
            name: "EmployerDashboard",
            component: () => import('@/components/employer/views/Dashboard.vue'),
          },
          {
            path: "/employer/browse-talent",
            name: "EmployerBrowseTalent",
            component: () => import('@/components/employer/views/BrowseTalent.vue'),
          },
          {
            path: "/employer/job-post",
            name: "EmployerJobPost",
            component: () => import('@/components/employer/views/JobPost.vue'),
          },
          {
            path: "/employer/chats",
            name: "EmployerChats",
            component: () => import('@/components/employer/views/Chats.vue'),
          },
          {
            path: "/employer/manage-account",
            name: "EmployerManageAccount",
            component: () => import('@/components/employer/views/ManageAccount.vue'),
          },
          {
            path: "/employer/support",
            name: "EmployerSupport",
            component: () => import('@/components/employer/views/Support.vue'),
          },
          {
            path: "/employer/logout",
            name: "EmployerLogout",
            component: () => import('@/components/employer/views/Logout.vue'),
          },
          {
            path: "/employer/delete-account",
            name: "EmployerDeleteAccount",
            component: () => import('@/components/employer/views/DeleteAccount.vue'),
          },
        ],
      },
      {
        path: "/employer/company",
        name: "EmployerCompanySetting",
        component: () => import('@/pages/employer/company/CompanySetting.vue'),
      },
      {
        path: "/employer/job-list",
        name: "EmployerJobList",
        component: () => import('@/pages/employer/job/Job.vue'),
      },
      {
        path: "/company/:slug",
        name: "Company",
        component: () => import('@/pages/company/Company.vue'),
      },
      {
        path: "/message",
        name: "Message",
        component: () => import('@/pages/message/Message.vue'),
      },
      {
        path: "/message/test",
        name: "MessageTest",
        component: () => import('@/components/message/CometChatConversation.vue'),
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: () => import('@/pages/util/pricing/Pricing.vue'),
      },
    ]
  },
  {
    path: "/user/login",
    name: "userLogin",
    component: () => import('@/pages/auth/Login.vue'),
  },
  {
    path: "/user/register",
    name: "userRegister",
    component: () => import('@/pages/auth/Register.vue'),
  },
  {
    path: "/user/register/confirm/:sessionId",
    name: "RegisterConfirm",
    component: () => import('@/pages/auth/RegisterConfirm.vue'),
  },
  {
    path: "/auth/register/verify/:id",
    name: "userVerify",
    component: () => import('@/components/auth/UserVerify.vue'),
  },
  {
    path: "/auth/reset-password",
    name: "ResetPasswordPrompt",
    component: () => import('@/pages/auth/ResetPasswordPrompt.vue'),
  },
  {
    path: "/auth/reset-password/:sessionId",
    name: "ResetPassword",
    component: () => import('@/pages/auth/ResetPassword.vue'),
  },
  {
    path: "/auth/google/callback",
    name: "GoogleAuth",
    component: () => import('@/pages/auth/GoogleAuth.vue'),
  },
  {
    path: '/not-found',
    component: () => import('@/pages/util/NotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    beforeEnter() {
      window.location.href = '/not-found';
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
