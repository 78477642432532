<template>
  <section class="flex flex-col lg:flex-row items-center justify-between max-w-6xl mx-auto px-6 py-16">
    <div class="lg:w-3/5 text-center lg:text-left">
      <h2 class="text-3xl font-semibold mb-4">{{ $t('landing.priceCard.title') }}</h2>
      <p class="text-slate-400 mb-8">
        {{ $t('landing.priceCard.description') }}
      </p>
      <div class="grid md:grid-cols-2 gap-6">
        <div class="w-full">
          <div class="space-y-4">
            <div class="bg-gray-100 dark:bg-slate-700 p-4 rounded-lg flex items-center gap-4">
              <img :src="smallCircleLightLogo" :alt="$t('landing.talent.serviceCard.talentbase')" class="w-12 h-12 rounded-full">
              <div class="flex flex-col items-start">
                <h4 class="font-semibold">{{ $t('landing.talent.serviceCard.talentbase') }}</h4>
                <p class="text-sm text-gray-500">{{ $t('landing.priceCard.talentbase1') }}</p>
              </div>
            </div>
            <div class="bg-gray-100 dark:bg-slate-700 p-4 rounded-lg flex items-center gap-4">
              <img :src="smallCircleLightLogo" :alt="$t('landing.talent.serviceCard.talentbase')" class="w-12 h-12 rounded-full">
              <div class="flex flex-col items-start">
                <h4 class="font-semibold">{{ $t('landing.talent.serviceCard.talentbase') }}</h4>
                <p class="text-sm text-gray-500">{{ $t('landing.priceCard.talentbase2') }}</p>
              </div>
            </div>
            <div class="bg-gray-100 dark:bg-slate-700 p-4 rounded-lg flex items-center gap-4">
              <img :src="smallCircleLightLogo" :alt="$t('landing.talent.serviceCard.talentbase')" class="w-12 h-12 rounded-full">
              <div class="flex flex-col items-start">
                <h4 class="font-semibold">{{ $t('landing.talent.serviceCard.talentbase') }}</h4>
                <p class="text-sm text-gray-500">{{ $t('landing.priceCard.talentbase3') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full">
          <div class="space-y-4">
            <div class="bg-gray-100 dark:bg-slate-700 p-4 rounded-lg flex items-center gap-4">
              <img :src="smallCircleDarkLogo" :alt="$t('landing.talent.serviceCard.jobads')" class="w-12 h-12 rounded-full">
              <div>
                <h4 class="font-semibold">{{ $t('landing.talent.serviceCard.jobads') }}</h4>
                <p class="text-sm text-gray-500">{{ $t('landing.priceCard.jobad1') }}</p>
              </div>
            </div>
            <div class="bg-gray-100 dark:bg-slate-700 p-4 rounded-lg flex items-center gap-4">
              <img :src="smallCircleDarkLogo" :alt="$t('landing.talent.serviceCard.jobads')" class="w-12 h-12 rounded-full">
              <div>
                <h4 class="font-semibold">{{ $t('landing.talent.serviceCard.jobads') }}</h4>
                <p class="text-sm text-gray-500">{{ $t('landing.priceCard.jobad2') }}</p>
              </div>
            </div>
            <div class="bg-gray-100 dark:bg-slate-700 p-4 rounded-lg flex items-center gap-4">
              <img :src="smallCircleDarkLogo" :alt="$t('landing.talent.serviceCard.jobads')" class="w-12 h-12 rounded-full">
              <div>
                <h4 class="font-semibold">{{ $t('landing.talent.serviceCard.jobads') }}</h4>
                <p class="text-sm text-gray-500">{{ $t('landing.priceCard.jobad3') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <a href="#" class="text-slate-400 font-semibold hover:underline">
          {{ $t('landing.priceCard.bookNow') }} →
        </a>
      </div>
    </div>

    <div class="lg:w-2/5 flex justify-center mt-10 lg:mt-0">
      <img :src="piggyBankImage" alt="Recruiting Illustration" class="w-80 lg:w-full max-w-sm">
    </div>
  </section>
</template>

<script>
export default {
  data() {
    const baseURL = process.env.VUE_APP_OBJECT_STORAGE_URL;

    return {
      smallCircleDarkLogo: `${baseURL}/resources/logo/small-circle-dark.png`,
      smallCircleLightLogo: `${baseURL}/resources/logo/small-circle-light.png`,
      piggyBankImage: `${baseURL}/resources/img/man-with-pig.png`,
    };
  },
};
</script>
