<script setup>
import { onMounted } from "vue";

onMounted(() => {
  // Cookie-Script einfügen, falls es noch nicht vorhanden ist
  if (!document.getElementById("cookie-script")) {
    const script = document.createElement("script");
    script.src = "//cdn.cookie-script.com/s/a36f70ad109fcedb0df36ee3043f412d.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.id = "cookie-script";
    document.head.appendChild(script);

    script.onload = () => {
      if (window.CookieScriptConsent && window.CookieScriptConsent.isConsentGiven("analytics")) {
        loadMatomo();
      }

      // Falls der Nutzer später seine Einstellung ändert
      window.addEventListener("CookieScriptConsentUpdated", () => {
        if (window.CookieScriptConsent.isConsentGiven("analytics")) {
          loadMatomo();
        }
      });
    };
  }
});

function loadMatomo() {
  if (window._mtm) return; // Verhindert mehrfaches Laden

  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });

  var g = document.createElement("script"),
    s = document.getElementsByTagName("script")[0];
  g.async = true;
  g.src = "http://analytics.fuchsjobs.de/js/container_ReDKa1Tc.js";
  s.parentNode.insertBefore(g, s);
}
</script>

<template>
  <div style="display: none;"></div>
</template>