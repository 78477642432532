<template>
  <LandingSearchTalent />
    <section class="relative md:py-24 py-16">
      <div class="container mt-10">
        <TalentCounter />
      </div>
      <div class="container mt-10">
        <EmployerPlatformCard />
      </div>
      <div class="container">
        <HireFlow />
      </div>
      <div class="container">
        <Employer3DCard />
      </div>
      <div class="container">
        <SinglePriceCard />
      </div>
      <PopularJob type="employer"/>
    </section>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import LandingSearchTalent from '@/pages/landing/LandingSearchTalent.vue'
import TalentCounter from '@/components/landing/talentCounter.vue'
import EmployerPlatformCard from '@/components/landing/EmployerPlatformCard.vue'
import SinglePriceCard from '@/components/landing/SinglePriceCard.vue'
import PopularJob from '@/components/landing/PopularJob.vue';
import HireFlow from '@/components/landing/HireFlow.vue'
import Employer3DCard from '@/components/landing/Employer3DCard.vue';

export default {
  name: 'LandingPageEmployer',
  components: {
    LandingSearchTalent: LandingSearchTalent,
    TalentCounter,
    EmployerPlatformCard,
    Employer3DCard,
    SinglePriceCard,
    PopularJob,
    HireFlow,
  }
}
</script>

<style lang="scss" scoped>
</style>
